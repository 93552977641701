/**
 * Important Notice:
 *
 * If Scripts like select2, owlCarousel or fancybox are needed,
 * the have to be moved from libs_notUsed to libs. Actually only
 * the flexnav files are in the folder libs.
 */

jQuery(function() {

  var $ = jQuery;
  $.ajaxSetup({
    cache: true
  });


  /* accordion */

  $(".accordion dd").hide();

  $('.accordion dt').on('click', function() {
    $(this).toggleClass('open');
    $(this).next('dd').slideToggle();
  });


  /* logoSlider */

  $(".logoSlider .sliderWrapper").each(function(){
    if($(this).children().length > 1){
      var navcontainer = $(this).parent().find('.slider-nav');
      $(this).addClass('owl-carousel owl-theme').owlCarousel({
        nav: false,
        //navContainer: navcontainer,
        //navText: ['<i class="icon-left"></i>','<i class="icon-right"></i>'],
        autoplay: true,
        items: 1,
        smartSpeed: 400,
        dots: false,
        loop: true,
        responsive : {
          450 : {
            items: 2,
          },
          800 : {
            items: 3,
          },
          1000 : {
            items: 4,
          }
        }
      });
    }
  });


  /* feature slider */

  $(".feature .sliderWrapper").each(function(){
    if($(this).children().length > 1){
      var navcontainer = $(this).parent().find('.slider-nav');
      $(this).addClass('owl-carousel owl-theme').owlCarousel({
        nav: false,
        //navContainer: navcontainer,
        //navText: ['<i class="icon-left"></i>','<i class="icon-right"></i>'],
        margin: 20,
        autoplay: true,
        items: 1,
        smartSpeed: 400,
        dots: true,
        loop: true,
        responsive : {
          700 : {
            items: 2,
          },
          1000 : {
            items: 3,
          },
        }
      });
    }
  });


  /* testimonial slider */

  $(".testimonial .sliderWrapper").each(function(){
    if($(this).children().length > 1){
      var navcontainer = $(this).parent().find('.slider-nav');
      $(this).addClass('owl-carousel owl-theme').owlCarousel({
        nav: true,
        navContainer: '.testimonial-slider-nav',
        navText: ['<i class="icon-left-open-big"></i>','<i class="icon-right-open-big"></i>'],
        autoplay: true,
        items: 1,
        smartSpeed: 400,
        dots: false,
        loop: true,
      });
    }
  });

  /* form select 2 */

  $(".wpcf7 select").select2({
      placeholder: 'Bitte wählen',
      minimumResultsForSearch: Infinity,
      width: "style",
  });

  /* overlay */

  var overlay = $('.overlay');

  if (typeof cookieName !== 'undefined' && typeof cookieExpires !== 'undefined' && !$.cookie(cookieName) && overlay.length) {

    var date = new Date(cookieExpires);
    date.setTime(date);

    setTimeout(function(){
      $.fancybox.open({
          src: '.overlay',
          type: 'inline',
          touch: false,
          baseClass: 'fancyboxOverlay',
          afterClose: function (instance, slide) {
              $.cookie(cookieName, true, {expires: date, path: '/'});
          }
      });
    }, 2000);

    $('.overlay .button').on('click', function() {
      $.cookie(cookieName, true, {expires: date, path: '/'});
    });

  }

});
